<template>
  <div class="columns">
    <div class="column">
      <text-input disabled :value="field.label">Question <span class="has-text-weight-bold has-text-danger" v-if="isRequired">*</span></text-input>

    </div>
    <div class="column">
      <text-input :value="fieldValue" @input="updateAttribute" :placeholder="placeholder">Answer</text-input>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    field: {
      type: Object,
      default: () => ({})
    }
  },

  methods: {
    updateAttribute(value) {
      this.$store.commit('job/setClosingCustomFieldAnswer', {
        label: this.field.label,
        value: value,
      })
      this.$emit('updatedInput')
    },
  },

  computed: {
    ...mapGetters('job', [
        'job'
    ]),
    hasJobClosingCustomFields() {
      return this.job.type?.closing_custom_fields?.length > 0
    },
    hasJobAnswers() {
      return this.job.answers !== null
    },
    fieldIndex() {
      return this.job.type.closing_custom_fields?.findIndex(item => item.label === this.field.label)
    },
    isRequired() {
      if(this.hasJobClosingCustomFields === false || this.fieldIndex === -1) {
        return false
      }
      return this.job.type.closing_custom_fields[this.fieldIndex].is_required
    },
    placeholder() {
      if(this.hasJobClosingCustomFields === false || this.fieldIndex === -1) {
        return ''
      }
      return this.job.type.closing_custom_fields[this.fieldIndex].placeholder
    },
    fieldValue() {
      if(this.hasJobAnswers === false) {
        return null
      }
      return this.job.answers[this.field.label] ?? null
    },
  }
}
</script>