<template>
  <base-modal :title="`${Naming.Technician} Override`" icon="user-cog">
    <loader v-bind="{ loading }">
      <p class="mb-1 has-text-weight-medium has-text-primary">You are about to override the {{ Naming.Technician.toLowerCase() }} for this scheduled {{ Naming.Job.toLowerCase() }}.</p>
      <p class="mb-1">When the actual {{ Naming.Job.toLowerCase() }} is created, the {{ Naming.Technician.toLowerCase() }} you select from the list below will be used instead of the main {{ Naming.Technician.toLowerCase() }} assigned to the {{ Naming.RecurringJob.toLowerCase() }} ({{ scheduledJob.recurring_job.technician.full_name }}).</p>
      <p class="mb-1" v-if="scheduledJob.technician_id"><span class="has-text-weight-medium">Current {{ Naming.Technician }}:</span> {{ scheduledJob.technician.full_name }}</p>
      <data-selector
        class="mb-4"
        searchable
        value-key="id"
        left-icon="user"
        left-icon-type="far"
        label-key="full_name"
        :prompt-label="`${Naming.Technician} not selected`"
        v-model="technician"
        :items="filteredTechnicians">
        Select New {{ Naming.Technician }}
      </data-selector>
      <p class="mt-4">&nbsp;</p>
    </loader>

    <template #footer>
      <div class="buttons">
        <action-button class="is-white is-rounded" @click="$close(false)">Cancel</action-button>
        <action-button :disabled="loading" v-bind="{ working }" class="is-primary has-text-weight-bold is-rounded" @click="overrideTechnician">Override</action-button>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import find from 'lodash/find'

export default {

  props: {
    recurringJobId: {
      type: Number,
      required: true
    },
    scheduledJobId: {
      type: Number,
      required: true
    }
  },

  data: () => ({
    loading: true,
    working: false,
    technician: null,
  }),

  computed: {
    ...mapState('recurring', [
      'scheduledJob'
    ]),

    ...mapGetters('user', {
      technicians: 'userList'
    }),

    filteredTechnicians() {
      if (!this.technicians.length) return []

      return this.technicians.filter(technician => {
        return ![
          this.scheduledJob.recurring_job.technician_id,
          this.scheduledJob.technician_id ? this.scheduledJob.technician_id : null
        ].includes(technician.id)
      })
    }
  },

  async created() {
    let { recurringJobId, scheduledJobId } = this
    await Promise.all([
      this.$store.dispatch('recurring/loadScheduledJob', { recurringJobId, scheduledJobId }),
      this.$store.dispatch('user/loadTechnicianList'),
    ])
    this.loading = false
  },

  methods: {
    overrideTechnician() {
      this.working = true
      this.$store.dispatch('recurring/overrideScheduledJobTechnician', {
        recurringJob: this.scheduledJob.recurring_job_id,
        scheduledJob: this.scheduledJobId,
        technician: this.technician
      })
        .then(this.$close(find(this.technicians, { id: this.technician })))
        .catch(error => {
          this.working = false
          this.$toast.error('Something went wrong. Please try again or contact support.')
        })
    }
  }

}
</script>
