<template>
  <div class="box grid gap-1 has-4-columns mb-1">
    <text-input
      class="is-marginless is-2-columns"
      :debounce="500"
      :placeholder="`Search by ${Naming.Site.toLowerCase()}, ${Naming.JobType.toLowerCase()}, or ${Naming.Technician.toLowerCase()} name`"
      :value="filters.search"
      @input="filterBy('search', $event)"
      left-icon="search"
      :label="false">
      <action-button
        v-if="filters.search"
        slot="right"
        @click="clearFilter('search')">
        <icon icon="times"/>
      </action-button>
    </text-input>
    <data-selector
      class="is-marginless"
      :value="filters.frequency"
      prompt-label="Filter by frequency"
      :items="frequencies"
      @input="filterBy('frequency', $event)">
      <action-button
        v-if="filters.frequency"
        slot="right"
        @click="clearFilter('frequency')">
        <icon icon="times"/>
      </action-button>
    </data-selector>
    <data-selector
      class="is-marginless"
      :value="filters.status"
      prompt-label="Filter by status"
      :items="statuses"
      @input="filterBy('status', $event)">
      <action-button
        v-if="filters.status"
        slot="right"
        @click="clearFilter('status')">
        <icon icon="times"/>
      </action-button>
    </data-selector>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { filtering } from '@/mixins'

class RecurringJobFilters {
  constructor() {
    this.search = ''
    this.status = null
    this.frequency = null
  }
}

export default {

  mixins: [
    filtering
  ],

  data: () => ({
    filters: new RecurringJobFilters,
    statuses: [
      { label: 'Active', value: 'active' },
      { label: 'Paused', value: 'paused' },
      // { label: 'Complete', value: 'complete' }, // @todo update the sweeper to close recurring jobs
    ],
  }),

  computed: {
    ...mapState('recurring', [
      'frequencies'
    ]),
  }

}
</script>
