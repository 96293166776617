<template>
  <page icon="sync" :title="`${Naming.RecurringJob} Manager`" v-bind="{ loading }">

    <template #tools>
      <div class="tabs is-toggle is-rounded">
        <ul>
          <router-link tag="li" exact :to="{ name: 'recurring-job-manager', params: { recurringJob: $route.params.recurringJob }}">
            <a class="has-text-weight-bold"><icon icon="th-list"/> {{Naming.Overview}}</a>
          </router-link>
          <router-link tag="li" exact :to="{ name: 'recurring-job-edit', params: { recurringJob: $route.params.recurringJob }}">
            <a>Edit Configuration</a>
          </router-link>
          <li v-if="$root.hasAbility('delete-recurring-jobs', 'Billow\\Models\\RecurringJob')">
            <a @click="deleteRecurringJob"><icon icon="trash"/> Delete</a>
          </li>
          <li>
            <a @click="help"><icon icon="question-circle"/> Help</a>
          </li>
        </ul>
      </div>
    </template>

    <router-view />
  </page>
</template>

<script>
import { mapGetters } from 'vuex'
import { deleteRecurringJob, recurringJobHelp } from '@/modals'

export default {

  data: () => ({
    loading: true
  }),

  async created() {
    await this.$store.dispatch('recurring/loadRecurringJob', this.$route.params.recurringJob)
    this.loading = false
  },

  computed: {
    ...mapGetters('recurring', [
      'recurringJob'
    ])
  },

  methods: {
    async deleteRecurringJob() {
      if (await deleteRecurringJob()) {
        this.$toast.success(this.Convert('Recurring job deleted successfully.'))
        this.$router.replace({ name: 'recurring-job-index' })
      }
    },

    help() {
      recurringJobHelp()
    },
  }

}
</script>
