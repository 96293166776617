<template>
<page v-bind="{ loading }" icon="sync" :title="Naming.RecurringJob" :loading-text="`Loading ${Naming.Jobs}…`">

  <div class="buttons" slot="tools">
    <new-job-dropdown />
  </div>

  <recurring-job-filters/>

  <div class="box is-paddingless">
    <table class="table is-fullwidth is-vcentered is-hoverable">
      <thead class="is-sticky">
        <tr>
          <th>{{ Naming.Site }} &amp; {{ Naming.JobType }}</th>
          <th>Frequency &amp; Exclusions</th>
          <th>Main {{ Naming.Technician }}</th>
          <th class="has-text-centered">{{ Naming.Jobs }} Created</th>
          <th>Run Period</th>
          <th class="is-narrow"></th>
        </tr>
      </thead>
      <tbody>
        <router-link tag="tr" class="cursor-pointer" :to="{ name: 'recurring-job-manager', params: { recurringJob: recurringJob.id }}" v-for="recurringJob in jobs.data" :key="recurringJob.id">
          <td>
            <div class="flex-link">
              <avatar class="mr-1" :path="recurringJob.site.logo_url" :name="recurringJob.site.name"/>
              <div class="is-flex flex-column">
                <span class="has-text-weight-bold">{{ recurringJob.site.name }}</span>
                <span>{{ recurringJob.type.description }}</span>
              </div>
            </div>
          </td>
          <td>
            <div class="is-flex flex-column">
              <span>{{ recurringJob.frequency.interval | ucfirst }}</span>
              <span class="is-size-7" v-if="recurringJob.frequency.exclusions.length">Excluding {{ recurringJob.frequency.exclusions.join(', ') }} (rule: {{ recurringJob.display_exclusion_rule }})</span>
              <span class="is-size-7" v-else>Without exclusions</span>
            </div>
          </td>
          <td>
            <link-user-overview :user="recurringJob.technician"/>
          </td>
          <td class="has-text-centered has-text-weight-light is-size-5">
            {{ recurringJob.jobs_count }}/{{ recurringJob.end_date ? recurringJob.scheduled_jobs_count : '∞' }}
          </td>
          <td>
            <div class="is-flex flex-column">
              <span class="has-tooltip-left has-tooltip-primary" :data-tooltip="`Timezone: ${recurringJob.site.address ? recurringJob.site.address.abbreviated_timezone : 'No Address'}`">{{ recurringJob.start_date | date('L') }}</span>
              <span class="is-size-7" v-if="recurringJob.end_date">until {{ recurringJob.end_date | date('L') }}</span>
              <span class="is-size-7" v-else>until paused.</span>
              <span class="is-size-7" v-if="recurringJob.next_scheduled_job">Next: in {{ recurringJob.next_scheduled_job.scheduled_for | fromNow(false) }}</span>
            </div>
          </td>
          <td>
            <arbitrary-status
              v-if="!recurringJob.complete"
              :status-class="['is-size-6 has-text-weight-medium', recurringJob.active ? 'success' : 'failed']"
              :status="recurringJob.active ? 'Active' : 'Paused'"/>
            <arbitrary-status
              v-else
              status-class="is-size-6 has-text-weight-medium success"
              status="Complete"/>
          </td>
        </router-link>
      </tbody>
    </table>
    <p class="has-text-centered" v-if="!jobs.data.length">
      No {{ Naming.Jobs.toLowerCase() }} found
    </p>
    <pager class="pl" :pageable="jobs" @nav="goToPage" :context="Naming.Job.toLowerCase()"/>
  </div>
</page>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { filtering } from '@/mixins'
import RecurringJobFilters from './partials/Filters'

export default {

  components: {
    RecurringJobFilters
  },

  data: () => ({
    loading: true,
  }),

  async beforeMount() {
    this.$store.commit('job/clear')
    await this.loadJobs()
    this.loading = false
  },

  destroyed() {
    this.$store.commit('job/clear')
  },

  methods: {
    goToPage(path) {
      this.$router.push(path)
      this.$scrollToTop()
    },
    loadJobs() {
      return this.$store.dispatch('job/loadJobs', {
        path: '/api' + this.$route.fullPath
      })
    },
  },

  computed: {
    ...mapGetters('job', [
      'jobs'
    ]),
  },

  watch: {
    $route: 'loadJobs'
  }

}
</script>
