<template>
  <base-modal :title="`Change Scheduled ${Naming.Job} Date`" icon="clock">
    <p class="mb-1 has-text-weight-medium has-text-primary">You are about to change the date for this scheduled {{ Naming.Job.toLowerCase() }}.</p>
    <p class="mb-1">The date you select below may not conflict with any other scheduled {{ Naming.Job.toLowerCase() }}, and it may not be before the {{ Naming.RecurringJobJob.toLowerCase() }} starts/started or after it is scheduled to end (if applicable).</p>

    <date-picker
      classes="is-medium"
      left-icon="calendar"
      v-model="date"
      v-bind="{ minDate, maxDate }"
      :error="$root.errors.date"
      alt-date-format="l, j F Y">
      Select a New Date
    </date-picker>

    <template #footer>
      <div class="buttons">
        <action-button class="is-white is-rounded" @click="$close(false)">Cancel</action-button>
        <action-button v-bind="{ working }" class="is-primary has-text-weight-bold is-rounded" @click="changeDate">Change Date</action-button>
      </div>
    </template>
  </base-modal>
</template>

<script>
export default {

  props: {
    scheduledJob: {
      type: Object,
      required: true
    },
    currentDate: {
      type: String,
      required: true
    },
    minDate: {
      type: String,
      required: true
    },
    maxDate: {
      type: String,
    },
  },

  data: () => ({
    date: null,
    working: false,
  }),

  created() {
    this.date = this.currentDate
  },

  destroyed() {
    this.$store.commit('billow/error/clear')
  },

  methods: {
    changeDate() {
      this.$store.dispatch('recurring/changeScheduledJobDate', {
        scheduledJob: this.scheduledJob.id,
        date: this.date
      })
        .then(this.$close)
        .catch(error => {
          this.working = false
          if (error.response.status !== 422) {
            this.$toast.error('Something went wrong. Please try again or contact support.')
          }
        })
    }
  }

}
</script>
