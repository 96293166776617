<template>
  <loader :loading="loadingInspections">
    <template #default>
      <div class="columns">
        <div class="column">
          <h2 class="is-size-5 has-text-primary">{{ Naming.Inspections }} Performed ({{ inspections.total }})</h2>
        </div>
        <div class="column">
          <form>
            <text-input
              classes="is-rounded"
              left-icon="search"
              :placeholder="`Search by ${Naming.Asset} Code, Barcode ID`"
              v-model="filters.search">
              <template #right>
                <div class="buttons has-addons">
                  <action-button :working="searching" @click="searchInspections" class="is-rounded">Search</action-button>
                  <action-button @click="clearFilter" left-icon="times" v-if="filters.search.length" class="is-rounded">Clear</action-button>
                </div>
              </template>
            </text-input>
          </form>
        </div>
      </div>
      <table class="table is-fullwidth is-vcentered is-hoverable">
        <thead>
          <tr>
            <th>
              {{ Naming.Inspection }}
              <br>
              {{ Naming.Asset }} Code / Internal ID
            </th>
            <th>
              {{ Naming.Checklist }}
              <br>
              {{ Naming.AssetType }}
            </th>
            <th>Barcode</th>
            <th>
              Outcome &amp; Score
            </th>
            <th class="is-narrow has-text-right">Completed</th>
          </tr>
        </thead>
        <tbody>
          <tr @click="goToInspection(inspection)" class="cursor-pointer" v-for="inspection in inspections.data" :key="inspection.id">
            <td>
              <span class="has-text-weight-bold">#{{ inspection.id }}</span>
              <br>
              <span>{{ inspection.asset.code }}</span>
              <span
                  v-if="inspection.asset.decommissioned_at"
                  class="ml-1 component-tag parent has-tooltip-bottom has-tooltip-multiline"
                  :data-tooltip="`${Naming.Asset} has been decommissioned. ${Naming.Inspection} report is not available.`">
                  <icon icon="trash"/>
                  <span class="letter">Decommissioned</span>
              </span>
            </td>
            <td>
              <span>{{ inspection.checklist ? inspection.checklist.name : '-' }}</span>
              <br>
              <small class="has-text-grey">{{ inspection.asset.type.name }}</small>
            </td>
            <td>
              <span v-if="inspection.asset && inspection.asset.barcode" class="is-family-monospace">{{ inspection.asset.barcode.data }}</span>
              <template v-else>Missing barcode <datalist></datalist></template>
            </td>
            <td :class="{
                'has-text-danger': inspection.failed_at,
                'has-text-success': inspection.passed_at,
              }">
              <span class="has-text-weight-bold is-flex align-center" v-if="inspection.failed_at">
                <warning-triangle />
                <span class="ml-1">Failed ({{ inspection.score +'/'+ inspection.answers.length }})</span>
              </span>
              <span class="has-text-weight-bold is-flex align-center" v-if="inspection.passed_at">
                <check-circle />
                <span class="ml-1">Passed ({{ inspection.score +'/'+ inspection.answers.length }})</span>
              </span>
            </td>
            <td>
              <inspection-status :inspection="inspection"/>
            </td>
          </tr>
        </tbody>
      </table>

      <p class="has-text-centered p-1" v-if="!inspections.data.length">No {{ Naming.Inspections }} to display.</p>

      <pager
        :pageable="inspections"
        :context="Naming.Inspection.toLowerCase()"
        jump-controls
        @nav="goToPage"
        :working="loadingInspections"/>
    </template>
  </loader>
</template>

<script>
import { mapGetters } from 'vuex'
import { flatMap } from 'lodash'

export default {

  props: {
    jobId: Number | String,
    siteId: Number | String,
  },

  data: () => ({
    loadingInspections: true,
    searching: false,
    filters: {
      search: ''
    }
  }),

  async mounted() {

    this.loadingInspections = false
  },

  methods: {
    goToInspection(inspection) {
      if(inspection.asset.decommissioned_at !== null) {
        return
      }
      this.$router.push({
        name: 'inspection-evidence',
        params: {
          inspection: inspection.id,
          site: this.job.site_id,
          zone: inspection.asset.zone_id,
          asset: inspection.asset_id
        }
      })
    },
    goToPage(path) {
      this.$emit('nav', path)
    },
    async searchInspections() {
      this.$emit('searchInspections', this.filters)
    },
    clearFilter() {
      this.filters.search = ''
      this.$emit('clearedSearch')
    }
  },

  computed: {
    ...mapGetters('inspection', [
      'inspections'
    ]),
    ...mapGetters('job', [
      'job'
    ]),
    failedInspections() {
      return this.inspections.data.filter(inspection => inspection.failed_at)
    },
    answers() {
      return flatMap(this.inspections.data, inspection => inspection.answers)
    },
    failedChecks() {
      return this.answers.filter(answer => answer.answer === 0)
    },
    passedChecks() {
      return this.answers.filter(answer => answer.answer === 1)
    },
  },
}
</script>
