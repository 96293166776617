<template>
  <base-modal v-bind="{ title }" icon="calendar">
    <template v-if="schedule.length">
      <p class="is-size-5 has-text-primary mb">Review &amp; Approval Required</p>
      <content-wrapper class="mb-1" v-if="!editing">
        <p class="has-text-black">Please examine the proposed schedule below before creating this {{ Naming.RecurringJob.toLowerCase() }}.</p>
        <p>Once the schedule has been created, {{ Naming.Jobs.toLowerCase() }} will be created as and when needed, and each scheduled {{ Naming.Job.toLowerCase() }} may be modified in advance (see <em>Help</em> for more information).</p>
      </content-wrapper>
      <content-wrapper class="mb-1" v-else>
        <p class="has-text-black">One or more of the changes you would like to make will cause the schedule to be rebuilt. As such, please examine the proposed amendment to the schedule below before saving it.</p>
        <p>If you approve the amended schedule, existing scheduled-{{ Naming.Jobs.toLowerCase() }} that have not yet run will be deleted, and the below schedule will replace it. {{ Naming.Jobs }} that have already been opened from the schedule will remain in tact.</p>
      </content-wrapper>

      <message class="mb-1 is-warning" v-if="recurringJob.open_first_job_immediately">
        <span class="has-text-weight-bold">Warning:</span>
        You have opted to open the first {{ Naming.Job.toLowerCase() }} immediately. This means the {{ Naming.Job.toLowerCase() }} may be performed before the scheduled date.
        <template v-if="!['daily', 'weekly'].includes(recurringJob.frequency.interval)">As the interval period is long, this is not recommended.</template>
      </message>

      <message class="mb-1 is-warning" v-if="changes">
        <span class="has-text-weight-bold">Note:</span>
        {{ changes }} {{ 'change' | plural(changes) }} {{ changes > 1 ? 'were' : 'was' }} made due to conflicts with your exclusions (weekends or public holidays).
      </message>

      <message class="mb-1 is-warning" v-if="discards">
        <span class="has-text-weight-bold">Note:</span>
        {{ discards }} {{ 'date' | plural(discards) }} {{ discards > 1 ? 'were' : 'was' }} skipped due to conflicts with your exclusions (weekends or public holidays).
      </message>

      <message class="is-light" v-for="date in schedule" :key="date.date">
        <p class="has-text-weight-bold is-size-5">{{ date.date | date('L') }}</p>
        <p class="has-text-grey">{{ date.date | fromNow(false) }} from now</p>
        <p v-if="date.change" class="mt has-text-info has-text-weight-bold is-size-7">
        Moved from {{ date.change.input_date | date('L') }} due to conflicting {{ date.change.due_to_weekend ? 'weekend' : `public holiday` }}
        </p>
      </message>
    </template>
    <template v-else>
      <content-wrapper>
        <p><strong>Given the configuration you've provided, a schedule cannot be created.</strong></p>
        <p v-if="discards && !changes">The exclusion rule you have chosen is preventing a schedule from being created, based on the starting date. For example, if you select a Saturday or Sunday, set the exclusion to weekends and the exclusion rule to not schedule conflicting {{ Naming.Jobs.toLowerCase() }}, a schedule cannot be created. In your case, {{ discards }} {{ 'day' | plural(discards) }} could not be scheduled, resulting in an empty schedule.</p>
        <p v-else>The configuration you have provided is limiting the schedule that can be created. If you have set the exclusion rule to move dates to the next/previous available day, but your period is too short, a schedule cannot be created.</p>
      </content-wrapper>
    </template>

    <template #footer>
      <div class="buttons">
        <action-button @click="$close(false)" class="is-white is-rounded">Cancel</action-button>
        <action-button @click="$close(true)" class="is-rounded has-text-weight-bold" :class="editing ? 'is-warning' : 'is-success'" right-icon="arrow-right" v-if="schedule.length">Approve &amp; {{ editing ? 'Amend' : 'Create' }} Schedule</action-button>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { mapState } from 'vuex'

export default {

  props: {
    schedule: {
      type: Array,
      required: true
    },
    changes: {
      type: Number,
      required: true
    },
    discards: {
      type: Number,
      required: true
    },
    editing: {
      type: Boolean,
      default: false,
    }
  },

  computed: {
    ...mapState('recurring', ['recurringJob']),
    title() {
      if (!this.schedule.length) return 'Cannot Plan a Schedule'

      return this.editing ? 'Amended Schedule Preview' : 'Schedule Preview'
    }
  }

}
</script>
