<template>
  <base-modal :title="`Delete ${Naming.RecurringJob}`" icon="exclamation-triangle">
    <p class="mb-1 has-text-weight-medium has-text-danger is-size-5">You are about to DELETE this {{ Naming.RecurringJob.toLowerCase() }}.</p>
    <p class="mb-1">Please choose whether or not you would like to also delete any {{ Naming.Jobs.toLowerCase() }} (and any of their {{ Naming.Inspections.toLowerCase() }}) that were created from this {{ Naming.RecurringJob.toLowerCase() }} schedule. If you choose not to delete this {{ Naming.Job.toLowerCase() }}, you will still be able to access them.</p>

    <checkbox-input
      :label="false"
      classes="is-circle is-danger"
      class="has-text-weight-bold has-text-black"
      :inner-label="`Also delete ${Naming.Jobs.toLowerCase()} and ${Naming.Inspections.toLowerCase()} created by this ${Naming.RecurringJob.toLowerCase()} schedule.`"
      v-model="deleteJobs">
    </checkbox-input>

    <template #footer>
      <div class="buttons">
        <action-button class="is-white is-rounded" @click="$close(false)">Cancel</action-button>
        <action-button v-bind="{ working }" class="is-danger has-text-weight-bold is-rounded" @click="confirmDelete">Delete</action-button>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { mapGetters } from 'vuex'

export default {

  data: () => ({
    date: null,
    working: false,
    deleteJobs: false
  }),

  destroyed() {
    this.$store.commit('billow/error/clear')
  },

  computed: {
    ...mapGetters('recurring', [
      'recurringJob'
    ])
  },

  methods: {
    confirmDelete() {
      this.$store.dispatch('recurring/deleteRecurring', { delete_jobs: this.deleteJobs })
        .then(this.$close)
        .catch(error => {
          this.working = false
          if (error.response.status !== 422) {
            this.$toast.error('Something went wrong. Please try again or contact support.')
          }
        })
    }
  }

}
</script>
