<template>
  <page v-bind="{ loading }" :title="Naming.ClientSites" icon="city" :loading-text="`Loading ${Naming.Sites.toLowerCase()}…`">
    <div slot="tools">
      <form>
        <text-input v-model="filters.search"
          @input="runFilters"
          :placeholder="`Search ${Naming.Sites.toLowerCase()}`"
          classes="level-item is-rounded">
          <div class="buttons has-addons" slot="right">
            <action-button @click="searchSites" left-icon="search">Search</action-button>
            <router-link
              :to="{ name: 'site-admin-create' }"
              class="button is-rounded is-primary" left-icon="plus"
              v-if="hasAbility('Billow\\Models\\Site', 'create-sites')"
            >
              <icon icon="plus"/>
              <span>{{ Naming.Site }}</span>
            </router-link>
          </div>
        </text-input>
      </form>
    </div>
    <div class="box is-paddingless">
      <table class="table is-fullwidth is-vcentered is-hoverable">
        <thead class="is-sticky">
        <tr>
          <th class="has-text-centered">{{ Naming.Site }}</th>
          <th class="has-text-centered">{{ Naming.Zones }}</th>
          <th class="has-text-centered">{{ Naming.Assets }}</th>
          <th class="has-text-centered">Open {{ Naming.Jobs }}</th>
          <th class="has-text-centered">{{ Naming.CustomFields }}</th>
          <th class="has-text-centered">Current Risk</th>
        </tr>
        </thead>
        <tbody>
        <tr class="cursor-pointer" @click="goTo(site)" v-for="site in sites.data" :key="site.id">
          <td>
            <span class="flex-link">
              <avatar class="mr-1" :path="site.logo_url"/>
              <span>{{ `${site.client.legal_name} - ${site.name}` }}</span>
            </span>
          </td>
          <td class="counter has-text-centered is-highlighted has-left-border">{{ site.zones_count }}</td>
          <td class="counter has-text-centered is-highlighted">{{ site.assets_count }}</td>
          <td class="counter has-text-centered is-highlighted has-right-border">{{ site.open_jobs_count }}</td>
          <td class="counter has-text-centered is-highlighted has-right-border">{{ site.custom_fields_count }}</td>
          <td class="has-text-centered">
            <current-risk :risk="site.risk"/>
          </td>
        </tr>
        </tbody>
      </table>

      <p class="has-text-centered" v-if="!sites.data.length">
        No {{ Naming.Sites.toLowerCase() }} found
      </p>

      <pager class="pl" :pageable="sites" @nav="goToPage"/>
    </div>
  </page>
</template>

<script>
import { mapGetters } from 'vuex'
import { debounce } from 'lodash'

export default {

  data: () => ({
    loading: true,
    going_to_page: false,
    namespace: 'site',
    filters: {
      search: ''
    }
  }),

  async created() {
    await this.loadSites()
    this.loading = false
  },

  computed: {
    ...mapGetters('site', [
      'sites'
    ]),
    ...mapGetters('auth', [
      'user'
    ])
  },

  methods: {
    goTo(site) {
      this.$router.push({
        name: 'site-overview',
        params: { site: site.id }
      })
    },
    goToPage(path) {
      this.$router.push({
        path: path,
        query: this.filters
      })
      this.$scrollToTop()
    },
    loadSites() {
      this.$scrollToTop()
      return this.$store.dispatch('site/loadSites', {
        path: '/api' + this.$route.fullPath,
        params: this.$route.query
      })
    },
    searchSites() {
      this.$router.push({
        name: 'site-admin-index',
        query: this.filters
      })
    },
    hasAbility(entityType, name) {
      return this.user.abilities.filter(ability => ability.entity_type === entityType && ability.name === name).length > 0
    },
    runFilters: debounce(function () {
      if (this.loading || this.going_to_page) return
      this.searchSites()
    }, 500),
  },

  watch: {
    '$route': 'loadSites'
  }
}
</script>
