<template>
  <section>
    <div class="grid">
      <div class="box p-2">
        <!-- Overview -->
        <div class="grid has-4-columns gap-1">
          <!-- Basic Info -->
          <article class="is-flex align-center is-3-columns">
            <avatar
              :size="48"
              class="mr-1 flex-shrink-none"
              :path="recurringJob.site.logo_url"
              :name="recurringJob.site.name"/>
            <div class="is-flex flex-column">
              <span class="is-size-5 has-text-weight-bold has-text-primary">{{ recurringJob.type.description }}</span>
              <span>
                <span class="has-text-grey">for</span>
                {{ recurringJob.site.client.legal_name }}
                <span class="has-text-grey">at</span>
                {{ recurringJob.site.name }}
                <span class="has-text-grey">starting </span>
                {{ recurringJob.start_date | date('L') }}
                <span v-if="recurringJob.start_date !== recurringJob.start_date_original" class="has-text-grey">
                  (originally started {{ recurringJob.start_date_original | date('L') }})
                </span>
                <template v-if="recurringJob.end_date">
                  <span class="has-text-grey">and finishing</span>
                  {{ recurringJob.end_date | date('L') }}
                </template>
                <span class="has-text-grey">(timezone: <span class="has-tooltip-bottom has-tooltip-primary has-text-weight-medium cursor-help" :data-tooltip="recurringJob.site.address ? recurringJob.site.address.abbreviated_timezone : 'No Address'">{{ recurringJob.site.address ? recurringJob.site.address.timezone : 'No Address' }}</span>)</span>
              </span>
            </div>
          </article>

          <!-- Status -->
          <article class="is-flex align-center justify-end">
            <template v-if="!recurringJob.complete">
              <arbitrary-status
                :status-class="['is-rounded is-size-6 has-text-weight-medium', recurringJob.active ? 'success' : 'failed']"
                :status="recurringJob.active ? 'Active' : 'Paused'"/>
              <action-button
                @click="() => recurringJob.active ? pause() : start()"
                class="is-rounded is-circular is-uppercase has-text-weight-medium ml"
                :left-icon="recurringJob.active ? 'pause' : 'play'"
                :class="recurringJob.active ? 'is-warning' : 'is-success'">
                {{ recurringJob.active ? 'Pause' : 'Resume' }}
              </action-button>
            </template>
            <arbitrary-status
              v-else
              status-class="is-size-6 has-text-weight-medium success"
              status="Complete"/>
          </article>
        </div>
      </div>
    </div>

    <!-- Meta & Stats -->
    <div class="grid has-4-columns gap-1 mt-1">
      <!-- Frequency -->
      <article class="box stat minimal flipped">
        <div class="headline">Frequency</div>
        <div class="value has-subvalue">
          {{ recurringJob.frequency.interval | ucfirst }}
          <span class="subvalue">
            <template v-if="recurringJob.frequency.exclusions.length">Excluding {{ recurringJob.display_exclusions }} <icon icon="exchange-alt" class="cursor-help tooltip has-text-primary" :data-tooltip="`Rule: ${recurringJob.display_exclusion_rule}`"/></template>
            <template v-else>Without exclusions</template>
          </span>
        </div>
      </article>

      <!-- Opened By -->
      <article class="box stat minimal flipped">
        <div class="headline">Opened By</div>
        <span class="value is-flex-align-center">
          <avatar
            :size="24"
            class="mr"
            :path="recurringJob.user.profile.picture_url"
            :name="recurringJob.user.full_name"/>
          <route name="user-overview" :params="{ user: recurringJob.user.id }">{{ recurringJob.user.full_name }}</route>
        </span>
      </article>

      <!-- Technician -->
      <article class="box stat minimal flipped">
        <div class="headline">Assigned {{ Naming.Technician }}</div>
        <span class="value is-flex-align-center">
          <avatar
            :size="24"
            class="mr"
            :path="recurringJob.technician.profile.picture_url"
            :name="recurringJob.technician.full_name"/>
          <route name="user-overview" :params="{ user: recurringJob.technician.id }">{{ recurringJob.technician.full_name }}</route>
        </span>
      </article>

      <!-- Count of Jobs -->
      <article class="box stat minimal flipped">
        <div class="headline">{{ Naming.Jobs }} Opened</div>
        <div class="value">{{ recurringJob.jobs_count }}/{{ recurringJob.end_date ? recurringJob.scheduled_jobs_count : '∞' }}</div>
      </article>
    </div>

    <div class="grid has-2-columns gap-1 mt-1" v-if="recurringJob.active && !recurringJob.locked && !recurringJob.locked">
      <!-- Next in Schedule -->
      <widget title="Next in Schedule">
        <p v-if="!recurringJob.next_scheduled_job">There are no upcoming scheduled {{ Naming.Jobs.toLowerCase() }}.</p>
        <template v-else>
          <p class="is-size-5 has-text-weight-bold">{{ recurringJob.next_scheduled_job.scheduled_for | date('L') }} <span class="has-text-weight-light">({{ recurringJob.next_scheduled_job.scheduled_for_relative }})</span></p>
          <p class="has-text-weight-light">
            <span class="has-text-primary">Assigned {{ Naming.Technician }}:</span>
            {{ recurringJob.next_scheduled_job.technician ? recurringJob.next_scheduled_job.technician.full_name : recurringJob.technician.full_name }}
          </p>
        </template>
      </widget>

      <!-- Re-openable Scheduled Job -->
      <widget title="Notices">
        <p v-if="!recurringJob.reopenable_scheduled_job">There are no notices available for this {{ Naming.RecurringJob.toLowerCase() }}.</p>
        <template v-else>
          <p class="mb-1">The job scheduled for today was not opened (reason: {{ recurringJob.reopenable_scheduled_job.skipped_reason || 'Previous job not completed' }}). If you would like to open the {{ Naming.Job.toLowerCase() }} anyway, click on the button below. This will not close out the previous {{ Naming.Job.toLowerCase() }}.</p>
          <action-button :working="reopening" @click="reopenSkippedScheduledJob" class="is-warning is-rounded">Open Anyway</action-button>
        </template>
      </widget>
    </div>

    <!-- Jobs Created -->
    <div class="grid mt-1">
      <widget class="is-slot-paddingless" :title="`Opened ${Naming.Jobs}`">
        <loader :loading="fetchingJobs">
          <p class="has-text-centered is-size-5 has-text-weight-light p-3" v-if="!jobs.data.length">No {{ Naming.Jobs.toLowerCase() }} have been created yet.</p>
          <table class="table is-fullwidth is-vcentered is-hoverable" v-if="jobs.data.length">
            <thead class="is-sticky">
              <tr>
                <th class="is-narrow">{{ Naming.Job }} #</th>
                <th>Type</th>
                <th>{{ Naming.Technician }}</th>
                <th>Start Date</th>
                <th>Completed</th>
                <th class="has-text-right">Status</th>
              </tr>
            </thead>
            <tbody>
              <router-link tag="tr" class="cursor-pointer" :to="{ name: 'job-handler', params: { job: job.id, site: job.site_id }}" v-for="job in jobs.data" :key="job.id">
                <td class="has-text-weight-bold">
                  #{{ job.number }}
                </td>
                <td>
                  {{ job.type.description }}
                </td>
                <td>
                  <link-user-overview avatar :avatar-size="32" :user="job.technician"/>
                </td>
                <td class="date">
                  {{ job.start_date | date('L') }}
                  <span class="is-block is-size-7">opened {{ job.created_at | fromNow }}</span>
                </td>
                <td class="date">
                  <template v-if="job.completed_at">{{ job.completed_at | date('L LTS') }}</template>
                  <template v-else>–</template>
                </td>
                <td class="has-text-right">
                  <current-status :status="job.current_status"/>
                </td>
              </router-link>
            </tbody>
          </table>
          <pager class="pl-1" v-if="jobs.data.length" :pageable="jobs" @nav="navJobs" :context="Naming.Job.toLowerCase()"/>
        </loader>
      </widget>
    </div>

    <!-- Schedule -->
    <div class="grid mt-1">
      <widget class="is-slot-paddingless" title="Schedule">
        <loader :loading="fetchingSchedule">
          <table class="table is-fullwidth is-hoverable is-vcentered">
            <thead>
              <tr>
                <th>Scheduled For <template v-if="recurringJob.site.address">(Timezone: {{ recurringJob.site.address.timezone }})</template></th>
                <th>{{ Naming.Technician }} Override</th>
                <th>Additional info</th>
                <th>{{ Naming.Job }}</th>
                <th class="is-narrow"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="scheduledJob in schedule.data" :key="scheduledJob.id">
                <td>
                  {{ scheduledJob.scheduled_for | date('L') }}
                  <span class="has-text-grey" v-if="!scheduledJob.job_id && !scheduledJob.skipped && !scheduledJob.deleted_at">({{ scheduledJob.scheduled_for_relative }})</span>
                  <span class="tag is-suffix is-rounded is-primary" v-if="recurringJob.next_scheduled_job && scheduledJob.id === recurringJob.next_scheduled_job.id">Up Next</span>
                </td>
                <td>
                  <span v-if="scheduledJob.technician">{{ scheduledJob.technician.full_name }}</span>
                  <span v-else>–</span>
                </td>
                <td class="has-text-weight-light">
                  <template v-if="scheduledJob.change">Moved from {{ scheduledJob.change.input_date | date('L') }} due to conflicting {{ scheduledJob.change.due_to_weekend ? 'weekend' : `public holiday` }}</template>
                  <template v-else>–</template>
                </td>
                <td class="has-text-weight-light" :class="[scheduledJob.job_id ? 'has-text-primary' : 'has-text-grey']">
                  <template v-if="scheduledJob.job">
                    <link-job-handler  :job="scheduledJob.job"/>
                    <span class="has-text-grey ml" v-if="scheduledJob.skipped_reason">(previously skipped)</span>
                  </template>
                  <span v-else :class="{ 'has-text-danger has-text-weight-normal': scheduledJob.skipped || scheduledJob.deleted_at }">
                    {{ scheduledJob.skipped ? `Skipped (${scheduledJob.skipped_reason || 'previous job incomplete'})` : (scheduledJob.job_id) ? 'Deleted' : (scheduledJob.deleted_at) ? 'Cancelled' : 'Pending' }}
                  </span>
                </td>
                <td>
                  <dropdown-wrapper position="bottom-end" v-if="recurringJob.active && $root.hasAbility('manage-recurring-jobs', 'Billow\\Models\\RecurringJob') === true">
                    <table-actions-trigger
                      v-if="!scheduledJob.locked"
                      slot="trigger"
                      slot-scope="{ isActive }"
                      v-bind="{ isActive }"/>

                    <dropdown-item
                      @click="changeDate(scheduledJob)">
                      <icon icon="clock" type="far"/>
                      <span>Change date</span>
                    </dropdown-item>

                    <dropdown-item
                      @click="overrideTechnician(scheduledJob)">
                      <icon icon="user-cog"/>
                      <span>{{ scheduledJob.technician_id ? 'Change ' + Naming.Technician + ' override' : 'Override ' + Naming.Technician }}</span>
                    </dropdown-item>
                    <dropdown-item
                      v-if="scheduledJob.technician_id"
                      @click="resetTechnician(scheduledJob)">
                      <icon icon/>
                      <span>Reset {{ Naming.Technician.toLowerCase() }}</span>
                    </dropdown-item>

                    <dropdown-item separator/>

                    <dropdown-item
                      class="is-danger"
                      @click="deleteScheduledJob(scheduledJob)">
                      <icon icon="times"/>
                      <span>Cancel this scheduled {{ Naming.Job.toLowerCase() }}</span>
                    </dropdown-item>

                  </dropdown-wrapper>
                </td>
              </tr>
            </tbody>
          </table>
          <pager class="pl-1" :pageable="schedule" @nav="navSchedule" context="scheduled job"/>
        </loader>
      </widget>
    </div>

  </section>
</template>

<script>
import { mapState } from 'vuex'
import { range, sortBy, first, uniqBy } from 'lodash'
import { scheduledJobTechnicianOverride, scheduledJobChangeDate } from '@/modals'
import moment from 'moment'

export default {

  data: () => ({
    closing: false,
    fetchingJobs: true,
    fetchingSchedule: true,
    reopening: false,
  }),

  created() {
    this.loadJobs()
    this.loadSchedule()
  },

  destroyed() {
    this.$store.commit('recurring/clearJobs')
    this.$store.commit('recurring/clearSchedule')
  },

  methods: {
    loadJobs() {
      this.fetchingJobs = true
      this.$store.dispatch('recurring/paginatedJobs').then(() => {
        this.fetchingJobs = false
      })
    },

    loadSchedule() {
      this.fetchingSchedule = true
      this.$store.dispatch('recurring/paginatedSchedule').then(() => {
        this.fetchingSchedule = false
      })
    },

    async start() {
      if (await this.$confirm({
        title: this.Convert('Start Recurring Job'),
        message: this.Convert('This recurring job is currently paused. By starting it up, new schedules will be created as and when needed, and jobs will be created based on those schedules.<br><br>Are you sure you would like to start this recurring job?'),
        confirmText: this.Convert('Start Recurring Job'),
        cancelText: 'Keep it Paused',
      })) {
        await this.$store.dispatch('recurring/start')
        this.$toast.info(this.Convert('Recurring job is now active.'))
      }
    },

    async pause() {
      if (await this.$confirm({
        title: this.Convert('Pause Recurring Job'),
        message: this.Convert('By pausing this currently active recurring job, new jobs will no longer be created according to its schedule. If it does not have an end date, new schedules will not be created until such time as it has been started again.<br><br> Are you sure you would like to pause this recurring job?'),
        confirmText: 'Pause',
        confirmClass: 'is-danger',
        cancelText: 'Keep it Active',
        cancelClass: 'is-primary'
      })) {
        await this.$store.dispatch('recurring/pause')
        this.$toast.info(this.Convert('Recurring job is now paused.'))
      }
    },

    navJobs(path) {
      this.$store.dispatch('recurring/paginatedJobs', path)
    },

    navSchedule(path) {
      this.$store.dispatch('recurring/paginatedSchedule', path)
    },

    async overrideTechnician(scheduledJob) {
      if (await scheduledJobTechnicianOverride({
        recurringJobId: this.recurringJob.id,
        scheduledJobId: scheduledJob.id
      })) {
        this.$store.dispatch('recurring/paginatedSchedule')
      }
    },

    async resetTechnician(scheduledJob) {
      if (await this.$confirm({
        title: this.Convert('Reset Technician'),
        message: `Are you sure you would like to change the ${this.Convert('technician').toLowerCase()} back to ${this.recurringJob.technician.full_name}?`,
        confirmText: this.Convert('Reset technician'),
        cancelText: `Keep ${scheduledJob.technician.name}`
      })) {
        await this.$store.dispatch('recurring/resetScheduledJobTechnician', scheduledJob.id)
        this.$store.dispatch('recurring/paginatedSchedule')
      }
    },

    async changeDate(scheduledJob) {
      var now = moment().format('YYYY-MM-DD HH:mm:ss')
      if (await scheduledJobChangeDate({
        scheduledJob,
        currentDate: scheduledJob.scheduled_for,
        minDate: now,
        maxDate: this.recurringJob.end_date
      })) {
        this.$store.dispatch('recurring/paginatedSchedule')
      }
    },

    async reopenSkippedScheduledJob() {
      this.reopening = true
      await this.$store.dispatch('recurring/reopenSkippedScheduledJob', this.recurringJob.id)
      await Promise.all([
        this.$store.dispatch('recurring/paginatedSchedule'),
        this.$store.dispatch('recurring/loadRecurringJob', this.$route.params.recurringJob),
      ])
      this.$toast.success(this.Convert('Job opened successfully.'))
      this.reopening = false
    },

    async deleteScheduledJob(scheduledJob) {
      if (await this.$confirm({
        title: this.Convert('Cancel Scheduled Job'),
        message: `Are you sure you would like to cancel this scheduled ${this.Convert('job').toLowerCase()}? If you cancel it, a ${this.Convert('job').toLowerCase()} will not be opened for the assigned ${this.Convert('technician').toLowerCase()} on ${this.$options.filters.date(scheduledJob.scheduled_for, 'D MMMM Y')}.<br><br><strong>Note:</strong> This action cannot be undone.`,
        confirmText: this.Convert('Cancel Scheduled Job'),
        confirmClass: 'is-danger has-text-weight-bold',
        cancelText: 'Leave Open',
        cancelClass: 'is-primary'
      })) {
        await this.$store.dispatch('recurring/deleteScheduledJob', scheduledJob.id)
        this.$store.dispatch('recurring/loadRecurringJob', this.$route.params.recurringJob)
        this.$store.dispatch('recurring/paginatedJobs')
      }
    }

  },

  computed: {
    ...mapState('recurring', [
      'recurringJob',
      'jobs',
      'schedule'
    ]),

    openJobs() {
      return this.recurringJob.jobs.filter(job => !job.completed_at)
    },

    latestJob() {
      return first(
        sortBy(this.openJobs, 'created_at')
      )
    },
  }
}
</script>
