<template>
  <base-modal>
    <div class="box">
      <h2 class="box-title is-size-6">
        <div class="flex-1 is-flex align-center justify-between">
          <div class="is-flex flex-column align-start">
            <span class="has-text-weight-bold">{{ Naming.Job }} #{{ eventProps.extendedProps.number }} at {{ eventProps.extendedProps.site }} <span class="has-text-weight-light">({{ eventProps.extendedProps.job_type }})</span></span>
            <span class="has-text-weight-light"> for {{ eventProps.extendedProps.client }}</span>
          </div>
          <current-status :status="eventProps.extendedProps.current_status"/>
        </div>
      </h2>
      <div class="grid has-2-columns column-gap-1 row-gap-2">
        <div class="is-flex align-center">
          <avatar
            class="mr-1"
            :path="eventProps.extendedProps.technician_avatar"
            :name="eventProps.extendedProps.technician"/>
          <div>
            <p>Assigned to {{ eventProps.extendedProps.technician }}</p>
            <p class="has-text-cool-blue-text-dark has-text-weight-medium">on {{ eventProps.extendedProps.created_at | date('L LTS') }}</p>
          </div>
        </div>
        <div class="grid has-2-columns gap-1">
          <summary-item
            title="Start Date"
            value-class="is-size-5 has-text-weight-light"
            :value="$options.filters.date(eventProps.start, 'L LTS')"/>
          <summary-item
            title="End Date"
            value-class="is-size-5 has-text-weight-light"
            :value="$options.filters.date(eventProps.extendedProps.end_at || eventProps.start, 'L LTS')"/>
        </div>
        <div class="buttons is-2-columns">
          <action-button
            @click="goToJob"
            class="is-primary is-rounded"
            right-icon="chevron-right">
            Manage {{ Naming.Job }}
          </action-button>
        </div>
      </div>
    </div>
  </base-modal>
</template>

<script>
export default {

  props: {
    eventProps: {
      type: Object,
      required: true,
    }
  },

  methods: {
    goToJob() {
      this.$close()
      this.$router.push({
        name: 'job-handler',
        params: {
          job: this.eventProps.id,
          site: this.eventProps.extendedProps.site_id,
        }
      })
    }
  }

}
</script>
