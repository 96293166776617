<template>
  <div>
    <template v-if="loaded">
      <div class="columns is-multiline">
        <div class="column is-3">
          <text-input
              class="is-marginless"
              :debounce="500"
              :placeholder="Naming.Job + ' Number, Reference'"
              :value="filters.search"
              @input="filterBy('search', $event)"
              left-icon="search"
              :label="false">
            <action-button
                v-if="filters.search"
                slot="right"
                @click="clearFilter('search')">
              <icon icon="times"/>
            </action-button>
          </text-input>
        </div>
        <div v-if="withClient" class="column is-3">
          <server-data-selector
            searchable
            class="is-marginless"
            :value="filters.client"
            :items="clientList"
            v-model="filters.client"
            left-icon="address-book"
            value-key="id"
            label-key="legal_name"
            :prompt-label="`Filter by ${Naming.Client}`"
            @input="filterBy('client', $event)"
            :on-type="searchClient"
            :on-lose-focus="clearClientFilter">
            <data-selector-client-slot
                slot="item"
                slot-scope="{ item }"
                :client="item"/>
            <action-button
                v-if="filters.client"
                slot="right"
                @click="clearClientFilter()">
              <icon icon="times"/>
            </action-button>
          </server-data-selector>
        </div>
        <div v-if="withSite" class="column is-3">
          <server-data-selector
            searchable
            class="is-marginless"
            :value="filters.site"
            :items="siteList"
            v-model="filters.site"
            left-icon="building"
            value-key="id"
            label-key="name"
            :prompt-label="`Filter by ${Naming.Site}`"
            @input="filterBy('site', $event)"
            :on-type="searchSite"
            :on-lose-focus="clearSiteFilter">
            <data-selector-site-slot
                slot="item"
                slot-scope="{ item }"
                :site="item"/>
            <action-button
                v-if="filters.site"
                slot="right"
                @click="clearSiteFilter()">
              <icon icon="times"/>
            </action-button>
          </server-data-selector>
        </div>
        <div class="column is-3">
          <server-data-selector
            searchable
            class="is-marginless"
            :value="filters.technician"
            :items="techniciansList"
            v-model="filters.technician"
            left-icon="user"
            value-key="id"
            label-key="full_name"
            :prompt-label="`Filter by ${Naming.Technician}`"
            @input="filterBy('technician', $event)"
            :on-type="searchTechnician"
            :on-lose-focus="clearTechnicianFilter">
            <data-selector-user-slot
                slot="item"
                slot-scope="{ item }"
                :user="item"/>
            <action-button
                v-if="filters.technician"
                slot="right"
                @click="clearTechnicianFilter()">
              <icon icon="times"/>
            </action-button>
          </server-data-selector>
        </div>
        <div v-if="advanced" class="column is-3">
          <server-data-selector
            searchable
            class="is-marginless"
            :value="filters.type"
            :items="jobTypeList"
            v-model="filters.type"
            left-icon="compress"
            value-key="id"
            label-key="description"
            :prompt-label="`Filter by ${Naming.JobTypes}`"
            @input="filterBy('type', $event)"
            :on-type="searchJobTypes"
            :on-lose-focus="clearJobTypeFilter">
            <action-button
                v-if="filters.type"
                slot="right"
                @click="clearJobTypeFilter()">
              <icon icon="times"/>
            </action-button>
          </server-data-selector>
        </div>
        <div v-if="advanced" class="column is-3">
          <server-data-selector
            searchable
            class="is-marginless"
            :value="filters.checklist"
            :items="checklistList"
            v-model="filters.checklist"
            left-icon="check"
            value-key="id"
            label-key="name"
            :prompt-label="`Filter by ${Naming.Checklist}`"
            @input="filterBy('checklist', $event)"
            :on-type="searchChecklistSelectList"
            :on-lose-focus="clearChecklistFilter">
            <action-button
                v-if="filters.checklist"
                slot="right"
                @click="clearChecklistFilter()">
              <icon icon="times"/>
            </action-button>
          </server-data-selector>
        </div>
        <div v-if="advanced" class="column is-3">
          <data-selector
              class="is-marginless"
              left-icon="circle-notch"
              :value="filters.status"
              prompt-label="Filter by current status"
              :items="jobStatuses"
              @input="filterBy('status', $event)">
            <action-button
                v-if="filters.status"
                slot="right"
                @click="clearFilter('status')">
              <icon icon="times"/>
            </action-button>
          </data-selector>
        </div>
        <div v-if="advanced" class="column is-3">
          <date-picker
              class="is-marginless"
              placeholder="Filter by start date (range)"
              mode="range"
              left-icon="calendar"
              :label="false"
              ref="startDate"
              :value="filters.start_date"
              @input="filterStartDate">
            <action-button
                v-if="filters.start_date"
                slot="right"
                @click="clearFilter('start_date')">
              <icon icon="times"/>
            </action-button>
          </date-picker>
        </div>
        <div v-if="advanced" class="column is-3">
          <checkbox-input :value="filters.past_due" @input="filterBy('past_due', $event)" inner-label="Show Past Due"/>
        </div>
        <div class="column is-narrow">
          <div class="buttons has-addons">
            <action-button
                @click="advanced = !advanced"
                class="is-primary is-inverted"
                :left-icon="advanced ? 'chevron-up' : 'chevron-down'">
              {{ advanced ? 'Less' : 'More' }}
            </action-button>
          </div>
        </div>
      </div>
    </template>
    <p v-else class="has-text-cool-blue-text">Loading Filters…</p>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { filtering } from '@/mixins'
import { indexOf } from 'lodash'
import Search from '@/utils/search'

export default {

  mixins: [
    filtering
  ],

  props: {
    withClient: {
      type: Boolean,
      default: true
    },
    withSite: {
      type: Boolean,
      default: true
    },
  },

  data: () => ({
    loaded: false,
    siteId: null,
    techniciansList: [],
    advanced: false,
    filters: {
      search: '',
      technician: '',
      client: '',
      site: '',
      start_date: '',
      status: '',
      checklist: '',
      type: '',
      past_due: false
    },
  }),

  computed: {
    ...mapGetters('client', [
      'clientList'
    ]),
    ...mapGetters('user', [
      'userList'
    ]),
    ...mapGetters('site', [
      'siteList'
    ]),
    ...mapGetters('list', [
      'jobStatuses'
    ]),
    ...mapGetters('jobType', [
      'jobTypeList'
    ]),
    ...mapGetters('checklist', [
      'checklistList'
    ]),

  },

  async created() {
    if(this.$route.name === 'site-jobs'){
      this.siteId = this.$route.params.site
    }

    let { status, start_date, type, checklist } = this.$route.query
    if (status || start_date || type || checklist) {
      this.advanced = true
    }

    this.getFilteredTechnicians()
    Search.searchByUri('', 'user/searchTechnician', this.$store, 0)
    Search.searchByUri('', 'checklist/searchChecklistSelectList', this.$store, 0)
    Search.searchByUri('', 'client/searchClient', this.$store, 0)
    Search.searchByUri('', 'site/searchSite', this.$store, 0)
    Search.searchByUri('', 'jobType/searchJobTypes', this.$store, 0)

    this.loaded = true
  },

  methods: {
    ...mapMutations('user', [
      'setUserList',
    ]),
    ...mapMutations('checklist', [
      'setChecklistsList',
    ]),
    ...mapMutations('client', [
      'setClientList',
    ]),
    ...mapMutations('site', [
      'setSiteList',
    ]),
    ...mapMutations('assetType', [
      'setAssetTypeList',
    ]),
    ...mapMutations('jobType', [
      'setJobTypeList',
    ]),

    filterStartDate(range) {
      if (!range) {
        this.clearFilter('start_date')
        return
      }
      this.filterBy('start_date', range)
      this.$refs.startDate.picker.redraw()
    },
    getFilteredTechnicians() {
      if (this.$route.params.site) {
        this.$store.dispatch('site/fetchTechnicians', this.$route.params.site)
            .then((data) => {
              this.techniciansList = data
            })
      } else {
        try {
          this.techniciansList = this.userList.filter((user) => {
            let siteIds = user.sites.map((site) => +site.id)
            if (siteIds.indexOf(+this.siteId) > -1) {
              return user;
            }
          })
        } catch (e) {
          this.techniciansList = this.userList
        }
      }
    },
    searchClient(text) {
      Search.debouncedSearchByUri(text, 'client/searchClient', this.$store)
    },
    searchSite(text) {
      Search.debouncedSearchByUri(text, 'site/searchSite', this.$store)
    },
    searchTechnician(text) {
      Search.debouncedSearchByUri(text, 'user/searchTechnician', this.$store)
    },
    searchJobTypes(text) {
      Search.debouncedSearchByUri(text, 'jobType/searchJobTypes', this.$store)
    },
    searchChecklistSelectList(text) {
      Search.debouncedSearchByUri(text, 'checklist/searchChecklistSelectList', this.$store)
    },
    clearTechnicianFilter() {
      this.clearFilter('technician')
      this.setUserList([])
      this.getFilteredTechnicians()
    },
    clearChecklistFilter() {
      this.clearFilter('checklist')
      this.setChecklistsList([])
    },
    clearClientFilter() {
      this.clearFilter('client')
      this.setClientList([])
    },
    clearSiteFilter() {
      this.clearFilter('site')
      this.clearFilter('zone')
      this.setSiteList([])
    },
    clearZoneFilter() {
      this.clearFilter('zone')
    },
    clearJobTypeFilter() {
      this.clearFilter('type')
      this.setJobTypeList([])
    }
  },

  watch: {
    userList() {
      if(!this.$route.params.site) {
        this.getFilteredTechnicians()
      }
    }
  }
}
</script>

<style scoped>
.box {
  z-index: 5;
}
</style>
