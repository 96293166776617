<template>
  <loader v-bind="{ loading }">
    <h4 class="is-size-4 has-text-primary is-flex align-center">
      <span class="flex-grow">Configuration</span>
      <div class="buttons">
        <action-button
          v-if="needsScheduleApproval"
          class="is-warning is-rounded has-text-weight-bold"
          v-bind="{ working }"
          right-icon="angle-right"
          @click="preview">
          Preview &amp; Update Schedule
        </action-button>
        <action-button
          v-else
          class="is-success is-rounded has-text-weight-bold"
          v-bind="{ working }"
          right-icon="angle-right"
          :disabled="$root.hasAbility('manage-recurring-jobs', 'Billow\\Models\\RecurringJob') === false || recurringJob.reference?.length > 255"
          @click="update">
          Update {{ Naming.RecurringJob }}
        </action-button>
      </div>
    </h4>

    <section class="box p-2 mt-1">
      <div class="grid has-3-columns gap-2">
        <div class="grid is-2-columns has-3-columns gap-1 align-content-start">
          <!-- First Run -->
          <date-picker
            :key="this.frequencyInterval"
            required
            :disabled="!intervalAllowsStartDateChange"
            alt-date-format="D, j F Y"
            :description="`The date on which the ${Naming.RecurringJob.toLowerCase()} starts.`"
            :value="recurringJob.start_date"
            @input="setStartDate"
            left-icon="calendar"
            :error="$root.errors.start_date">
            Starts On
          </date-picker>

          <!-- Frequency -->
          <data-selector
            required
            :description="`How often should new ${Naming.Jobs.toLowerCase()} be created?`"
            :error="$root.errors['frequency.interval']"
            :items="frequencies"
            :value="recurringJob.frequency.interval"
            @input="setFrequencyInterval">
            Frequency
          </data-selector>

          <date-picker
            ref="endDate"
            clearable
            alt-date-format="D, j F Y"
            :description="`Should the ${Naming.RecurringJob.toLowerCase()} end?`"
            hint="If not set, we’ll keep the schedule going for you."
            :min-date="earliestEndDate"
            :value="recurringJob.end_date"
            @input="setEndDate"
            left-icon="calendar"
            :error="$root.errors.end_date">
            End On
          </date-picker>

          <div class="grid is-3-columns has-2-columns gap-1">
            <!-- Exclusion Modes -->
            <data-selector
              multiple
              tag-class="is-primary is-rounded"
              show-selected
              prompt-label="No exclusions set"
              :value="recurringJob.frequency.exclusions"
              @input="setFrequencyExclusions"
              :items="exclusions"
              description="Don’t include weekends and/or public holidays?">
              Exclusions
            </data-selector>

            <!-- Exclusion Rule -->
            <data-selector
              :disabled="!recurringJob.frequency.exclusions.length || recurringJob.frequency.interval === 'daily'"
              :value="recurringJob.frequency.exclusion_rule"
              @input="setFrequencyExclusionRule"
              :items="exclusionRules"
              prompt-label="Select a rule"
              description="How should we deal with conflicts?">
              Exclusion Rule
            </data-selector>
          </div>

          <div class="grid is-3-columns has-2-columns gap-1">
            <!-- Job Type -->
            <data-selector
              required
              :prompt-label="`Select a ${Naming.JobType.toLowerCase()}…`"
              :items="jobTypeList"
              :error="$root.errors.job_type_id"
              :value="recurringJob.job_type_id"
              @input="setJobType"
              value-key="id"
              :description="`What ${Naming.JobType.toLowerCase()} should be used for ${Naming.Jobs.toLowerCase()} created in the future?`"
              label-key="description">
              {{ Naming.JobType }}
            </data-selector>

            <!-- Technician -->
            <data-selector
              required
              searchable
              :error="$root.errors.technician_id"
              :items="userList"
              :value="recurringJob.technician_id"
              @input="setTechnician"
              :prompt-label="`Assign a ${Naming.Technician.toLowerCase()}`"
              :description="`Select the ${Naming.Technician.toLowerCase()} who will be assigned to new ${Naming.Jobs.toLowerCase()} going forward.`"
              label-key="full_name"
              left-icon="user"
              value-key="id">
              Technician
              <data-selector-user-slot
                slot="item"
                slot-scope="{ item }"
                :user="item"
                :admin="Boolean(isAdmin(item.roles))"/>
            </data-selector>
          </div>

          <!-- Reference -->
          <text-input
            class="is-3-columns"
            :error="validateInput(recurringJob.reference, 255, 'Reference')"
            :value="recurringJob.reference"
            hint="Such as a purchase order or work order number"
            @input="setReference">
            Reference
          </text-input>

          <!-- Allow Early Closure -->
          <!--<checkbox-input
            class="is-3-columns"
            classes="is-circle"
            :value="recurringJob.allow_early_closure"
            @input="setEarlyClosure"
            inner-label="Allows technicians to mark jobs created from this recurring job as closed from their devices.">
            Early Job Closure
          </checkbox-input>-->

          <!-- Special Instructions -->
          <text-area
            class="is-3-columns"
            rows="6"
            :description="`These will be included in the email sent to the ${Naming.Technician.toLowerCase()} for each new ${Naming.Job.toLowerCase()}.`"
            :errors="$root.errors.special_instructions"
            :value="recurringJob.special_instructions"
            @input="setSpecialInstructions">
            Special Instructions
          </text-area>
        </div>

        <div>
          <!-- Site -->
          <field-label>Assigned {{ Naming.Site}}</field-label>
          <p class="is-size-5 has-text-weight-light">{{ recurringJob.site.name }}</p>

          <!-- Zones -->
          <section class="mt-1">
            <div class="is-flex align-center">
              <field-label required class="flex-grow is-marginless">Change {{ Naming.Zones }}</field-label>
              <div class="is-size-7 has-text-weight-medium tracking-wide is-uppercase" v-if="recurringJob.site.zones.length">
                <a @click="selectAllZones" class="mr-1">Select All</a>
                <a @click="deselectAllZones">Deselect all</a>
              </div>
            </div>

            <div class="scroll-view mt" style="height: 30rem">
              <checkbox-group
                v-if="recurringJob.site.zones.length"
                required
                :label="false"
                :error="$root.errors.zones"
                :value="recurringJob.zones"
                @input="setZones"
                :items="recurringJob.site.zones"
                label-key="name"
                value-key="id"/>
              <p class="p-1" v-else>
                <span class="is-block has-text-weight-bold mb-1">This {{ Naming.Site.toLowerCase() }} does not have any {{ Naming.Zones.toLowerCase() }}.</span>
                <span class="is-block has-text-weight-light mr-2">When a new {{ Naming.Job.toLowerCase() }} is created from this {{ Naming.RecurringJob.toLowerCase() }}, the assigned {{ Naming.Technician.toLowerCase() }} will be able to create {{ Naming.Zones.toLowerCase() }}. When new {{ Naming.Zones.toLowerCase() }} have been created, they will appear in this list, at which time you may select which {{ Naming.Zones.toLowerCase() }} should be assigned to {{ Naming.Jobs.toLowerCase() }} created in the future.</span>
              </p>
            </div>
          </section>
        </div>
      </div>
    </section>
  </loader>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import { find, range, uniqBy, map, pick, isEqual, cloneDeep } from 'lodash'
import { schedulePreview } from '@/modals'
import textLengthValidation from '@/utils/validationUtils'
import Technician from "@/components/nav/SideBar/Technician.vue";

export default {
  components: {Technician},

  data: () => ({
    working: false,
    activeSite: null,
    loading: true,
    frequencies: [
      { value: 'daily', label: 'Daily' },
      { value: 'weekly', label: 'Weekly' },
      { value: 'every-2-weeks', label: 'Every 2 Weeks' },
      { value: 'monthly', label: 'Monthly' },
      { value: 'quarterly', label: 'Quarterly' },
      { value: 'biannually', label: 'Biannually' },
      { value: 'annually', label: 'Annually' },
    ],
    exclusions: [
      { value: 'weekends', label: 'Weekends' },
      { value: 'public-holidays', label: 'Public Holidays' },
    ],
    exclusionRules: [
      { value: 'discard', label: 'Don’t schedule' },
      { value: 'next-day', label: 'Schedule for the next available day' },
      { value: 'previous-day', label: 'Schedule for the previous available day' },
    ],
    days: [
      { value: 'monday', label: 'Monday' },
      { value: 'tuesday', label: 'Tuesday' },
      { value: 'wednesday', label: 'Wednesday' },
      { value: 'thursday', label: 'Thursday' },
      { value: 'friday', label: 'Friday' },
      { value: 'saturday', label: 'Saturday' },
      { value: 'sunday', label: 'Sunday' },
    ],
    initialScheduleIdentifiers: {}
  }),

   created() {
    Promise.all([
      this.$store.dispatch('user/loadTechnicianSelectList'),
      this.$store.dispatch('jobType/loadJobTypeList'),
    ]).then(() => {
      if (!this.frequencyExclusionsMapped) {
        this.setFrequencyExclusions(
          map(
            this.recurringJob.frequency.exclusions,
            exclusion => this.exclusions.find(e => e.value === exclusion)
          )
        )
        this.$store.commit('recurring/frequencyExclusionsMapped')
      }

      this.initialScheduleIdentifiers = pick(
        cloneDeep(this.recurringJob),
        ['start_date', 'end_date', 'frequency']
      )

      this.loading = false
    })
  },

  methods: {
    ...mapMutations('recurring', [
      'setEndDate',
      'setEarlyClosure',
      'setFrequencyExclusionRule',
      'setFrequencyExclusions',
      'setJobType',
      'setReference',
      'setSpecialInstructions',
      'setStartDate',
      'setTechnician',
      'setZones',
    ]),

    setFrequencyInterval(interval) {
      this.$store.commit('recurring/setFrequencyInterval', interval)
      this.setFrequencyExclusionRule('discard')
      this.setStartDate(this.initialScheduleIdentifiers.start_date)
    },

    async approvePreview({ schedule, changes, discards }) {
      return await schedulePreview({ schedule, changes, discards, editing: true })
    },

    preview() {
      this.working = true
      this.$store.dispatch('recurring/previewUpdate').then(async response => {
        this.working = false
        if (await this.approvePreview(response.data)) {
          this.$toast.info('Generating schedule…')
          this.update()
        }
      }).catch(() => {
          this.$toast.error('Please check your configuration.')
          this.working = false
        })
    },

    update() {
      this.working = true
      this.$store.dispatch('recurring/save').then(async response => {
        this.working = false
        this.$toast.success(this.Convert(this.$lang.job.saved))
        await this.$store.dispatch('recurring/loadRecurringJob', response.data.id)
        this.$router.push({
          name: 'recurring-job-manager',
          params: {
            recurringJob: response.data.id
          }
        })
      }).catch(error => {
        this.$toast.error(this.Convert('Something prevented the recurring job from being created. Please try again or contact support.'))
        this.working = false
      })
    },

    selectAllZones() {
      this.setZones(this.recurringJob.site.zones.map(zone => zone.id))
    },

    deselectAllZones() {
      this.setZones([])
    },
    validateInput(fieldValue, fieldLength, fieldName) {
      return textLengthValidation(fieldValue, fieldLength, fieldName)
    },
  },

  computed: {
    ...mapGetters('site', ['siteList']),
    ...mapGetters('jobType', ['jobTypeList']),
    ...mapGetters('user', ['userList']),
    ...mapGetters('recurring', ['recurringJob']),
    ...mapState('recurring', ['frequencyExclusionsMapped']),

    zoneList() {
      return (this.activeSite) ? this.activeSite.zones : []
    },

    isAdmin() {
      return roles => find(roles, role => role.name === 'admin')
    },

    earliestEndDate() {
      if(this.recurringJob.complete) return this.recurringJob.end_date

      let { interval } = this.recurringJob.frequency

      let add = {
        'daily': { days: 1 },
        'weekly': { weeks: 1 },
        'every-2-weeks': { weeks: 2 },
        'monthly': { months: 1 },
        'quarterly': { months: 3 },
        'biannually': { months: 6 },
        'annually': { years: 1 }
      }[interval]

      return moment().add(add).format('Y-MM-DD')
    },

    needsScheduleApproval() {
      return !isEqual(
        this.initialScheduleIdentifiers,
        pick(this.recurringJob, ['start_date', 'end_date', 'frequency'])
      )
    },

    frequencyInterval() {
      return this.recurringJob.frequency.interval
    },

    intervalAllowsStartDateChange() {
      return !['daily'].includes(this.frequencyInterval)
    }
  },

  watch: {
    frequencyInterval() {
      if (moment(this.recurringJob.end_date).isBefore(this.earliestEndDate)) {
        this.setEndDate(this.earliestEndDate)
      }
      this.$refs.endDate.picker.set('minDate', this.earliestEndDate)
    }
  }

}
</script>
