<template>
  <base-modal :title="`How ${Naming.Job} Scheduling Works`" icon="question-circle">
    <content-wrapper>
      <p>When you create a recurring {{ Naming.Job.toLowerCase() }} schedule, {{ Naming.Jobs.toLowerCase() }} are planned in advance according to the configuration your provide. In the case your configuration may need to be tweaked, this plan is presented to you before the schedule is created, at which point you can approve and create the schedule, or cancel and modify your configuration.</p>
      <h4>Exclusions</h4>
      <p>If you’d like to skip weekends and/or public holidays, you may select the applicable options in the Exclusions dropdown list. You can also set a rule for what happens when a conflict is detected, such as moving to the next or previous available day, or skipping it altogether.</p>
      <message class="is-info"><strong>Note:</strong> Exclusion rules are not available for daily schedules.</message>
      <h4>How {{ Naming.RecurringJobs }} are Processed</h4>
      <p>Schedules are processed by the system hourly, and new {{ Naming.Jobs.toLowerCase() }} will be created according to the schedule in the timezone of the applicable {{ Naming.Site.toLowerCase() }}. As such, the starting date and ending date (optional) must be set for the applicable day in the country of the {{ Naming.Site.toLowerCase() }}.</p>
      <h4>Modifications to the Schedule</h4>
      <p>Once the schedule has been created, you will be directed to the {{ Naming.RecurringJob }} manager. Here, you can see the schedule that was created, and make modifications where necessary. For each scheduled {{ Naming.Job.toLowerCase() }} shown in the list, you may change the date (so long as it doesn’t overlap with another scheduled {{ Naming.Job.toLowerCase() }} or fall outside the start/end dates) and override which {{ Naming.Technician.toLowerCase() }} must be assigned to the {{ Naming.Job.toLowerCase() }}.</p>
      <message class="is-warning"><strong>Note:</strong> These modifications may only be made <em>before</em> the actual {{ Naming.Job.toLowerCase() }} has been opened for the assigned {{ Naming.Technician.toLowerCase() }}.</message>
      <h4>Skipping Scheduled {{ Naming.Jobs.toLowerCase() }}</h4>
      <p>If a {{ Naming.Job.toLowerCase() }} has been opened by the schedule and not closed by the time the next {{ Naming.Job.toLowerCase() }} is due to be opened, <strong>that next {{ Naming.Job.toLowerCase() }} will be skipped</strong>. The scheduled {{ Naming.Job.toLowerCase() }} in the {{Naming.RecurringJob}} manager will indicate this.</p>
      <p>When {{ Naming.Jobs.toLowerCase() }} are skipped by the system, a notification will be sent to admins that have opted into the applicable notification channel indicating which scheduled {{ Naming.Job.toLowerCase() }} were skipped. At this point, any of these admins may open skipped {{ Naming.Job.toLowerCase() }} anyway.</p>
    </content-wrapper>
  </base-modal>
</template>

<script>
export default {

}
</script>
