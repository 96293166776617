<template>
  <page icon="sync" :title="`New ${Naming.RecurringJob}`" v-bind="{ loading }">
    <div slot="tools">
      <router-link class="button is-cool-blue is-rounded" :to="{ name: 'recurring-job-index' }">
        <icon icon="angle-left"/>
        <span>{{ Naming.RecurringJobs }}</span>
      </router-link>
    </div>

    <h4 class="is-size-4 has-text-primary is-flex align-center">
      <span class="flex-grow">Schedule Configuration</span>
      <div class="buttons">
        <action-button
          @click="help"
          class="is-rounded is-cool-blue"
          left-icon="question-circle">
          Help
        </action-button>
        <action-button
          :disabled="$root.hasAbility('create-recurring-jobs', 'Billow\\Models\\RecurringJob') === false || recurringJob.reference.length > 255"
          class="is-success is-rounded has-text-weight-bold"
          v-bind="{ working }"
          right-icon="angle-right"
          @click="preview">
          Preview &amp; Create
        </action-button>
      </div>
    </h4>

    <p>Setup your {{ Naming.RecurringJob }} schedule using the form below.</p>

    <section class="box p-2 mt-1">
      <div class="grid has-3-columns gap-2">
        <div class="grid is-2-columns has-3-columns gap-1 align-content-start">
          <!-- First Run -->
          <date-picker
            required
            alt-date-format="l, j F Y"
            :description="`When should the ${Naming.RecurringJob.toLowerCase()} start?`"
            :min-date="tomorrow"
            :value="recurringJob.start_date"
            @input="setStartDate"
            left-icon="calendar"
            :error="$root.errors.start_date">
            Start On
          </date-picker>

          <!-- Frequency -->
          <data-selector
            required
            :description="`How often should new ${Naming.Jobs} be created?`"
            :error="$root.errors['frequency.interval']"
            :items="frequencies"
            :value="recurringJob.frequency.interval"
            @input="setFrequencyInterval">
            Frequency
          </data-selector>

          <date-picker
            ref="endDate"
            clearable
            alt-date-format="l, j F Y"
            :description="`Should the ${Naming.RecurringJob.toLowerCase()} end?`"
            hint="If not set, we’ll keep the schedule going for you."
            :min-date="earliestEndDate"
            :value="recurringJob.end_date"
            @input="setEndDate"
            left-icon="calendar"
            :error="$root.errors.end_date">
            End On
          </date-picker>

          <checkbox-input
            :label="false"
            class="is-3-columns"
            classes="is-circle"
            :value="recurringJob.open_first_job_immediately"
            @input="openFirstJobImmediately"
            :inner-label="['daily', 'weekly'].includes(recurringJob.frequency.interval) ? `Open the first ${Naming.Job.toLowerCase()} immediately` : `Open the first ${Naming.Job.toLowerCase()} immediately (not recommended due to long interval)`"/>

          <div class="grid is-3-columns has-2-columns gap-1">
            <!-- Exclusion Modes -->
            <data-selector
              multiple
              tag-class="is-primary"
              show-selected
              delete-buttons
              prompt-label="No exclusions set"
              :value="recurringJob.frequency.exclusions"
              @input="setFrequencyExclusions"
              :items="exclusions"
              description="Don’t include weekends and/or public holidays?">
              Exclusions
            </data-selector>

            <!-- Exclusion Rule -->
            <data-selector
              :disabled="!recurringJob.frequency.exclusions.length || recurringJob.frequency.interval === 'daily'"
              :value="recurringJob.frequency.exclusion_rule"
              @input="setFrequencyExclusionRule"
              :items="exclusionRules"
              prompt-label="Select a rule"
              description="How should we deal with conflicts?">
              Exclusion Rule
            </data-selector>
          </div>

          <div class="grid is-3-columns has-2-columns gap-1">
            <!-- Job Type -->
            <server-data-selector
              required
              searchable
              :prompt-label="`Select a ${Naming.JobType.toLowerCase()}…`"
              :items="jobTypeList"
              :error="$root.errors.job_type_id"
              :value="recurringJob.job_type_id"
              @input="setJobType"
              value-key="id"
              :description="`What type of ${Naming.Job} will this schedule create?`"
              label-key="description"
              :on-type="searchJobTypes"
              :on-lose-focus="clearJobTypeFilter">
              {{ Naming.JobType }}
            </server-data-selector>

            <!-- Technician -->
            <server-data-selector
              required
              searchable
              :error="$root.errors.technician_id"
              :items="userList"
              :value="recurringJob.technician_id"
              @input="setTechnician"
              :prompt-label="`Assign a ${Naming.Technician.toLowerCase()}`"
              :description="`Select the ${Naming.Technician.toLowerCase()} who will be assigned to scheduled ${Naming.Jobs.toLowerCase()}.`"
              label-key="full_name"
              left-icon="user"
              value-key="id"
              :on-type="searchTechnician"
              :on-lose-focus="clearTechnicianFilter">
              {{ Naming.Technician }}
              <data-selector-user-slot
                slot="item"
                slot-scope="{ item }"
                :user="item"
                :admin="Boolean(isAdmin(item.roles))"/>
            </server-data-selector>
          </div>

          <!-- Reference -->
          <text-input
            class="is-3-columns"
            :error="validateInput(recurringJob.reference, 255, 'Reference')"
            :value="recurringJob.reference"
            hint="Such as a purchase order or work order number"
            @input="reference">
            Reference
          </text-input>

          <!-- Allow Early Closure -->
          <!-- <checkbox-input
            classes="is-circle"
            class="is-3-columns"
            :value="recurringJob.allow_early_closure"
            @input="setEarlyClosure"
            inner-label="Allow technicians to mark jobs created from this recurring job as closed from their devices.">
            Early Job Closure
          </checkbox-input> -->

          <!-- Special Instructions -->
          <text-area
            class="is-3-columns"
            rows="6"
            :description="`These will be included in the email sent to the ${Naming.Technician.toLowerCase()} for each new ${Naming.Job.toLowerCase()}.`"
            :errors="$root.errors.special_instructions"
            :value="recurringJob.special_instructions"
            @input="setSpecialInstructions">
            Special Instructions
          </text-area>
        </div>

        <div>
          <!-- Site -->
          <server-data-selector
            required
            searchable
            :value="recurringJob.site_id"
            :error="$root.errors.site_id"
            @input="setSite"
            :items="siteList"
            :prompt-label="`Select a ${Naming.Site.toLowerCase()}…`"
            :description="`Which ${Naming.Site.toLowerCase()} is applicable to this schedule?`"
            left-icon="building"
            label-key="name"
            value-key="id"
            :on-type="searchSite"
            :on-lose-focus="clearSiteFilter">
            {{ Naming.Site }}
            <data-selector-site-slot
              slot="item"
              slot-scope="{ item }"
              :site="item"/>
          </server-data-selector>

          <!-- Zones -->
          <section>
            <div class="is-flex align-center">
              <field-label required class="flex-grow is-marginless">{{ Naming.Zones }}</field-label>
              <div class="is-size-7 has-text-weight-medium tracking-wide is-uppercase" v-if="zoneList.length">
                <a @click="selectAllZones" class="mr-1">Select All</a>
                <a @click="deselectAllZones">Deselect all</a>
              </div>
            </div>

            <div class="scroll-view mt" style="height: 30rem">
              <checkbox-group
                v-if="zoneList.length"
                required
                :label="false"
                :error="$root.errors.zones"
                :value="recurringJob.zones"
                @input="setZones"
                :items="zoneList"
                label-key="name"
                value-key="id"/>
              <p class="has-text-centered" v-else>
                Please select a {{ Naming.Site.toLowerCase() }} to show its {{ Naming.Zones.toLowerCase() }}.
              </p>
            </div>
          </section>
        </div>
      </div>
    </section>
  </page>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { find } from 'lodash'
import { schedulePreview, recurringJobHelp } from '@/modals'
import Search from '@/utils/search'
import textLengthValidation from '@/utils/validationUtils'

export default {

  data: () => ({
    working: false,
    activeSite: null,
    loading: true,
    frequencies: [
      { value: 'daily', label: 'Daily' },
      { value: 'weekly', label: 'Weekly' },
      { value: 'every-2-weeks', label: 'Every 2 Weeks' },
      { value: 'monthly', label: 'Monthly' },
      { value: 'quarterly', label: 'Quarterly' },
      { value: 'biannually', label: 'Biannually' },
      { value: 'annually', label: 'Annually' },
    ],
    exclusions: [
      { value: 'weekends', label: 'Weekends' },
      { value: 'public-holidays', label: 'Public Holidays' },
    ],
    exclusionRules: [
      { value: 'discard', label: 'Don’t schedule' },
      { value: 'next-day', label: 'Schedule for the next available day' },
      { value: 'previous-day', label: 'Schedule for the previous available day' },
    ],
    days: [
      { value: 'monday', label: 'Monday' },
      { value: 'tuesday', label: 'Tuesday' },
      { value: 'wednesday', label: 'Wednesday' },
      { value: 'thursday', label: 'Thursday' },
      { value: 'friday', label: 'Friday' },
      { value: 'saturday', label: 'Saturday' },
      { value: 'sunday', label: 'Sunday' },
    ],
  }),

  created() {
    this.clearAllStores()
    Search.searchByUri('', 'jobType/searchActiveJobTypes', this.$store, 0)
    Search.searchByUri('', 'site/searchSite', this.$store, 0)
    Search.searchByUri('', 'user/searchTechnician', this.$store, 0)
    this.$store.commit('recurring/clear')
    this.setStartDate(moment().add(1, 'day').format())
    if (this.$route.query.site) {
      this.setSite(Number(this.$route.query.site))
    }
    this.loading = false
  },

  methods: {
    ...mapMutations('user', [
      'setUserList',
    ]),
    ...mapMutations('site', [
      'setSiteList',
    ]),
    ...mapMutations('jobType', [
      'setJobTypeList',
    ]),
    ...mapMutations('recurring', [
      'setEndDate',
      'setEarlyClosure',
      'setFrequencyExclusionRule',
      'setFrequencyExclusions',
      'setJobType',
      'setReference',
      'setSpecialInstructions',
      'setStartDate',
      'setTechnician',
      'setZones',
      'openFirstJobImmediately',
      'reference'
    ]),

    setFrequencyInterval(interval) {
      this.$store.commit('recurring/setFrequencyInterval', interval)
      this.setFrequencyExclusionRule('discard')
    },

    setSite(id) {
      this.$store.commit('recurring/setSite', id)
      this.activeSite = find(this.siteList, site => Number(site.id) === Number(id))
    },

    async approvePreview({ schedule, changes, discards }) {
      return await schedulePreview({ schedule, changes, discards })
    },

    help() {
      recurringJobHelp()
    },

    preview() {
      this.working = true
      this.$store.dispatch('recurring/preview').then(async response => {
        this.working = false
        if (await this.approvePreview(response.data)) {
          this.$toast.info('Generating schedule…')
          this.store()
        }
      }).catch(() => {
          this.$toast.error('Please check your configuration.')
          this.working = false
        })
    },

    store() {
      this.working = true
      this.$store.dispatch('recurring/store').then(response => {
        this.working = false
        this.$toast.success(this.Convert(this.$lang.job.saved))
        this.$router.push({
          name: 'recurring-job-manager',
          params: {
            recurringJob: response.data.id
          }
        })
      }).catch(error => {
        this.$toast.error(this.Convert('Something prevented the recurring job from being created. Please try again or contact support.'))
        this.working = false
      })
    },

    selectAllZones() {
      this.setZones(this.activeSite.zones.map(zone => zone.id))
    },

    deselectAllZones() {
      this.setZones([])
    },
    searchSite(text) {
      Search.debouncedSearchByUri(text, 'site/searchSite', this.$store)
    },
    searchTechnician(text) {
      Search.debouncedSearchByUri(text, 'user/searchTechnician', this.$store)
    },
    searchJobTypes(text) {
      Search.debouncedSearchByUri(text, 'jobType/searchActiveJobTypes', this.$store)
    },
    clearTechnicianFilter() {
      this.setUserList([])
    },
    clearJobTypeFilter() {
      this.setJobTypeList([])
    },
    clearSiteFilter() {
      this.setSiteList([])
    },
    clearAllStores() {
      this.setUserList([])
      this.setSiteList([])
      this.setJobTypeList([])
    },
    validateInput(fieldValue, fieldLength, fieldName) {
      return textLengthValidation(fieldValue, fieldLength, fieldName)
    },
  },

  computed: {
    ...mapGetters('site', ['siteList']),
    ...mapGetters('jobType', ['jobTypeList']),
    ...mapGetters('user', ['userList']),
    ...mapGetters('recurring', ['recurringJob']),

    zoneList() {
      return (this.activeSite) ? this.activeSite.zones : []
    },

    isAdmin() {
      return roles => find(roles, role => role.name === 'admin')
    },

    tomorrow() {
      return moment().add(1, 'days').format()
    },

    earliestEndDate() {
      let { interval } = this.recurringJob.frequency

      let add = {
        'daily': { days: 1 },
        'weekly': { weeks: 1 },
        'every-2-weeks': { weeks: 2 },
        'monthly': { months: 1 },
        'quarterly': { months: 3 },
        'biannually': { months: 6 },
        'annually': { years: 1 }
      }[interval]

      return moment().add(add).format('Y-MM-DD')
    },

    frequencyInterval() {
      return this.recurringJob.frequency.interval
    }
  },

  watch: {
    frequencyInterval() {
      if (moment(this.recurringJob.end_date).isBefore(this.earliestEndDate)) {
        this.setEndDate(this.earliestEndDate)
      }
      this.$refs.endDate.picker.set('minDate', this.earliestEndDate)
    }
  },

  beforeRouteLeave(to, from, next) {
    this.clearAllStores()
    next()
  }

}
</script>
